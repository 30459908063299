
import { AfterViewInit, Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';


@Component({
    selector: 'calsoft-zigzagimage1',
    templateUrl: './zigzagimage1.component.html',
    styleUrls: ['./zigzagimage1.component.scss']
})
export class Zigzagimage1Component   implements AfterViewInit {
  constructor(  private commonService: CommonService){}
   @Input() data : any;
   @Input() item : any;
   @Input() images : any;
   public bannerServerPath = environment.commonImageApi + '/commonSlider/';
    
   public serverPath1 = environment.commonImageApi + '/home/';

  @ViewChild('storyContainer', { static: false }) storyContainer!: ElementRef;

  successStories = [
    { image: 'assets/images/google-meet-people.jpg', title: 'Official Website of Tulsa Airport', description: 'Tulsa Airport, a prominent name in aviation, sought Cubix’s expertise to enhance its digital presence.' },
    { image: 'assets/images/google-meet-people.jpg', title: 'Project 2', description: 'Project 2 description...' },
    { image: 'assets/images/google-meet-people.jpg', title: 'Project 3', description: 'Project 3 description...' },
    { image: 'assets/images/google-meet-people.jpg', title: 'Project 4', description: 'Project 4 description...' },
    { image: 'assets/images/google-meet-people.jpg', title: 'Project 5', description: 'Project 5 description...' },
    { image: 'assets/images/google-meet-people.jpg', title: 'Project 6', description: 'Project 6 description...' },
    { image: 'assets/images/google-meet-people.jpg', title: 'Project 7', description: 'Project 7 description...' },
    { image: 'assets/images/google-meet-people.jpg', title: 'Project 8', description: 'Project 8 description...' }
  ];

  ngAfterViewInit(): void {
    this.observeImages();
  }
  link(url) {

    this.commonService.goToLink(url)
   
  }


  observeImages(): void {
    // const images = document.querySelectorAll('.scroll-effect');

    // const observer = new IntersectionObserver((entries) => {
    //   entries.forEach(entry => {
    //     const img = entry.target as HTMLElement;
    //     const scrollTop = window.scrollY;
    //     const translateValue = Math.min(scrollTop / 10, 10); // Max move up 30px

    //     if (entry.isIntersecting) {
    //       img.style.transform = `translateY(-${translateValue}px)`;
    //     } else {
    //       img.style.transform = `translateY(0px)`; // Reset when out of view
    //     }
    //   });
    // }, { threshold: 0.3 });

    // images.forEach(img => observer.observe(img));
  }
}
