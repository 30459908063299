import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-animated-scroll-two',
  templateUrl: './animated-scroll-two.component.html',
  styleUrls: ['./animated-scroll-two.component.scss'],
  animations: [
    trigger('slideUp', [
        transition(':enter', [
            style({ bottom: '-100%', opacity: 0 }),
            animate('1s ease-in-out', style({ bottom: '0%', opacity: 1 }))
        ]),
        transition(':leave', [
            animate('1s ease-in-out', style({ bottom: '100%', opacity: 0 }))
        ])
    ])
]
})
export class AnimatedScrollTwoComponent implements OnInit, AfterViewInit {
  @Input() data: any;
  @Input() item: any;
  @Input() images: any;

  @ViewChild('scrollContainer') scrollContainer!: ElementRef;
  @ViewChild('progressBar') progressBar!: ElementRef;
  @ViewChildren('sectionItem') sectionItems!: QueryList<ElementRef>;
  @ViewChild('scrollContainerTwo') scrollContainerTwo!: ElementRef;

  currentIndex = 0;
  currentImageIndex = 0;
  prevImage: any = null;
  currentImage: any = null;
  lastScrollY = 0;

  public bannerServerPath = environment.commonImageApi + '/commonSlider/';
  public serverPath1 = environment.commonImageApi + '/home/';

  constructor(private commonService: CommonService) {}

  ngOnInit() {
    if (this.images?.length > 0) {
      this.currentImage = this.images[0]; // Set first image initially
    }
  }

  ngAfterViewInit() {
    this.updateProgressBar();
    this.detectActiveSection();
    this.setupIntersectionObserver();

    const container = this.scrollContainer.nativeElement;
    let touchStartX = 0;
    let touchMoveX = 0;

    container.addEventListener('touchstart', (e: TouchEvent) => {
      touchStartX = e.touches[0].clientX;
    });

    container.addEventListener('touchmove', (e: TouchEvent) => {
      touchMoveX = e.touches[0].clientX;
    });

    container.addEventListener('touchend', () => {
      const difference = touchStartX - touchMoveX;

      if (Math.abs(difference) > 50) {
        if (difference > 0 && this.currentIndex < container.children[0].children.length - 1) {
          this.currentIndex++;
        } else if (difference < 0 && this.currentIndex > 0) {
          this.currentIndex--;
        }
      }

      this.smoothScrollTo(this.currentIndex);
    });
  }

  setupIntersectionObserver() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = this.sectionItems.toArray().findIndex(
              (el) => el.nativeElement === entry.target
            );
            if (index !== -1) {
              this.currentImageIndex = index;
              this.currentImage = this.images[this.currentImageIndex];
              this.prevImage = index > 0 ? this.images[index - 1] : null;
              this.updateProgressBar();
            }
          }
        });
      },
      { threshold: 0.6 }
    );

    this.sectionItems.forEach((section) => observer.observe(section.nativeElement));
  }

  link(url: string) {
    this.commonService.goToLink(url);
  }

  detectActiveSection() {
    let index = 0;
    this.sectionItems.forEach((section, i) => {
      const rect = section.nativeElement.getBoundingClientRect();
      if (rect.top >= 0 && rect.top < window.innerHeight / 2) {
        index = i;
      }
    });

    if (index !== this.currentImageIndex) {
      this.currentImageIndex = index;
    }
  }

  @HostListener("window:scroll", [])
  onScroll() {
    const currentScrollTop = window.scrollY;

    if (currentScrollTop > this.lastScrollY) {
      this.updateProgressBar();
    } else {
      this.updateProgressBar();
    }

    this.lastScrollY = currentScrollTop;
  }

  nextImage() {
    if (this.currentImageIndex < this.images.length - 1) {
      this.prevImage = this.currentImage;
      this.currentImageIndex++;
      this.currentImage = this.images[this.currentImageIndex];

      setTimeout(() => {
        this.prevImage = null;
      }, 3000);
    }
  }

  updateProgressBar() {
    if (!this.scrollContainer || !this.progressBar || !this.sectionItems) return;

    const scrollableElement = this.scrollContainer.nativeElement;
    const sections = this.sectionItems.toArray();
    const totalSections = sections.length;

    if (totalSections === 0) return;

    let currentSectionIndex = 0;
    sections.forEach((section, index) => {
      const rect = section.nativeElement.getBoundingClientRect();
      if (rect.top >= 0 && rect.top < window.innerHeight / 2) {
        currentSectionIndex = index;
      }
    });

    let progress = (currentSectionIndex / (totalSections - 1)) * 100;
    if (progress > 100) progress = 100;
    if (progress < 0) progress = 0;

    this.progressBar.nativeElement.style.transition = "height 0.5s ease-out";
    this.progressBar.nativeElement.style.height = `${progress}%`;

    if (this.currentImageIndex === -1) {
      this.currentImageIndex = 0;
      this.currentImage = this.images[0];
    }

    if (currentSectionIndex !== this.currentImageIndex) {
      this.currentImageIndex = currentSectionIndex;
      this.updateRightSideImage();
    }
  }

  updateRightSideImage() {
    if (this.currentImageIndex >= this.images.length) return;

    this.prevImage = this.currentImage;
    this.currentImage = this.images[this.currentImageIndex];

    if (this.currentImageIndex === this.images.length - 1) {
      this.currentImage = this.images[this.images.length - 1];
    }

    setTimeout(() => {
      this.prevImage = null;
    }, 500);
  }

  smoothScrollTo(index: number) {
    if (this.scrollContainer) {
      const container = this.scrollContainer.nativeElement;
      const targetPos = index * window.innerWidth;
      container.scrollTo({ left: targetPos, behavior: 'smooth' });
    }
  }
}
