<div class="marquee">
    <div 
      [style.font-size]="item.customField18" 
      [ngStyle.lt-md]="{ 'font-size': item.customField17, 'font-family': item.fontFamily || 'cursive', 'color': item.customField26 || 'black', 'animation-duration': (item.customField38 || 5) + 's' }"
      [ngStyle]="{ 'font-family': item.fontFamily || 'cursive', 'color': item.customField26 || 'black', 'animation-duration': (item.customField38 || 5) + 's' }">
      
      <span>
        <calsoft-editor-text [text]="item?.title"></calsoft-editor-text>
      </span>
      <span>
        <calsoft-editor-text [text]="item?.title"></calsoft-editor-text> <!-- Duplicate for smooth looping -->
      </span>
  
    </div>
</div>
