<div class="common-var">
    <div fxHide fxShow.gt-md class="web-container">
        <div class="main-container">
            <div class="inner-container">
                <div class="review-container" fxLayout="column">
                    <div class="review-details">
                        <div class="review-information">
                            <h2 class="top-heading-container">
                                <span class="left-word">{{item.customField20}}</span>
                                <span class="right-word">{{item.customField21}}</span>
                            </h2>
                        </div>
                        <p class="review-description">
                            {{item.customField22}}
                        </p>

                    </div>
                    <div *ngIf="item?.customField15 || item?.customField16; else noImages" class="review-sample-image"
                        fxLayout="row" fxLayoutAlign="center center">
                        <figure *ngIf="item?.customField15">
                            <img [ngStyle]="{'width': isMobile ? '120px' : '200px'}"
                                [src]="serverPath1 + item.customField15" [alt]="item.customField15">
                        </figure>
                        <figure *ngIf="item?.customField16">
                            <img [ngStyle]="{'width': isMobile ? '120px' : '200px'}"
                                [src]="serverPath1 + item.customField16" [alt]="item.customField16">
                        </figure>
                    </div>

                    <ng-template #noImages>
                        <div style="margin-bottom: 2.5rem;"></div>
                    </ng-template>


                    <div class="review-courosel">
                        <div class="review-lists" #reviewsContainer>

                            
                            <div *ngFor="let img of images" class="review-item">
                                <p class="review-item-description">
                                    “{{img.customField10}}”
                                </p>
                                <div class="review-item-info">
                                    <div class="review-item-info-logo">
                                        <div class="image-data">
                                            <div id="profile-image">
                                                {{img.customField12}}
                                            </div>
                                        </div>
                                        <div class="user-data">
                                            <p class="user-data-text">
                                                {{img.customField11}}
                                            </p>
                                            <div class="user-data-review">
                                                <svg class="rating-style" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                                    viewBox="0 0 22 20">
                                                    <path
                                                        d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z">
                                                    </path>
                                                </svg><svg class="rating-style" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                                    viewBox="0 0 22 20">
                                                    <path
                                                        d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z">
                                                    </path>
                                                </svg><svg class="rating-style" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                                    viewBox="0 0 22 20">
                                                    <path
                                                        d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z">
                                                    </path>
                                                </svg><svg class="rating-style" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                                    viewBox="0 0 22 20">
                                                    <path
                                                        d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z">
                                                    </path>
                                                </svg><svg class="rating-style" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                                    viewBox="0 0 22 20">
                                                    <path
                                                        d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z">
                                                    </path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <a *ngIf="img.customField13" class="review-item-directlink"><img style="width: 90px;"
                                            [src]="serverPath1 + img.customField13" [alt]="img.customField13"></a>
                                
                                </div>
                            </div>
                           


                        </div>
                        <!-- <div class="loader-review">
      <div class="loader-container">
        <div class="loader-line" [style.height.%]="loaderHeight" >
  
        </div>
      </div>
  
    </div> -->

                    </div>

                </div>

            </div>

        </div>
    </div>
    <div fxHide fxShow.lt-md class="mobile-container">
        <div class="main-container">
            <div class="inner-container">
                <div class="review-container" fxLayout="column">
                    <div class="review-details">
                        <div class="review-information">
                            <h2 class="top-heading-container">
                                <span class="left-word">{{item.customField20}} </span>
                                <span class="right-word">{{item.customField21}}</span>
                            </h2>
                        </div>
                        <p class="review-description">
                            {{item.customField22}}
                        </p>

                    </div>
                    <div *ngIf="item?.customField15 || item?.customField16; else noImages" class="review-sample-image"
                        fxLayout="row" fxLayoutAlign="center center">
                        <figure *ngIf="item?.customField15">
                            <img [ngStyle]="{'width': isMobile ? '120px' : '200px'}"
                                [src]="serverPath1 + item.customField15" [alt]="item.customField15">
                        </figure>
                        <figure *ngIf="item?.customField16">
                            <img [ngStyle]="{'width': isMobile ? '120px' : '200px'}"
                                [src]="serverPath1 + item.customField16" [alt]="item.customField16">
                        </figure>
                    </div>

                    <ng-template #noImages>
                        <div style="margin-bottom: 2.5rem;"></div>
                    </ng-template>
                    <div class="review-courosel">
                        <div class="review-lists" #reviewsContainer>
                           
                            <div  *ngFor="let img of images"  class="review-item" contentAnim [aniName]="item.customField36">
                                <p class="review-item-description">
                                    “{{img.customField10}}”
                                </p>
                                <div class="review-item-info">
                                    <div class="review-item-info-logo">
                                        <div class="image-data">
                                            <div id="profile-image" contentAnim [aniName]="item.customField36">
                                                {{img.customField12}}
                                            </div>
                                        </div>
                                        <div class="user-data">
                                            <p class="user-data-text" contentAnim [aniName]="item.customField36">
                                                {{img.customField11}}
                                            </p>
                                            <div class="user-data-review">
                                                <svg class="rating-style" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                                    viewBox="0 0 22 20">
                                                    <path
                                                        d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z">
                                                    </path>
                                                </svg><svg class="rating-style" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                                    viewBox="0 0 22 20">
                                                    <path
                                                        d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z">
                                                    </path>
                                                </svg><svg class="rating-style" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                                    viewBox="0 0 22 20">
                                                    <path
                                                        d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z">
                                                    </path>
                                                </svg><svg class="rating-style" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                                    viewBox="0 0 22 20">
                                                    <path
                                                        d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z">
                                                    </path>
                                                </svg><svg class="rating-style" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                                    viewBox="0 0 22 20">
                                                    <path
                                                        d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z">
                                                    </path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <a  *ngIf="img.customField13"  class="review-item-directlink"><img style="width: 90px;"
                                            [src]="serverPath1 + img.customField13" [alt]="img.customField13"></a>
                                   
                                </div>
                            </div>
                        


                        </div>
                        <!-- <div class="loader-review">
    <div class="loader-container">
      <div class="loader-line" [style.height.%]="loaderHeight" >
  
      </div>
    </div>
  
  </div> -->

                    </div>

                </div>

            </div>

        </div>
    </div>
</div>