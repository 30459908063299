<div>

  <!-- <h2 class="textwithImage"    [ngStyle]="{'font-family': data?.websiteFontFamily}"  [style.font-size]="item.customField18" 
    
    [ngStyle]="{'font-family': data?.websiteFontFamily}" [innerHTML]="item.title">

      <span  [ngStyle]="{'font-family': data?.websiteFontFamily}">
        {{item.customField19}}
      </span>
    </h2> -->
  <calsoft-editor-text class="textwithImage" [ngStyle]="{'font-family': data?.websiteFontFamily}"
    [style.font-size]="item.customField18" [ngStyle]="{'font-family': data?.websiteFontFamily}" [data]="data"
    [text]="item.title">
  </calsoft-editor-text>

</div>