<div fxHide.lt-md>
    <div style=" padding: 40px 0px;" [style.background-color]="item.customField8"
        [style.backgroundImage]="'url(' + serverPath+item.customField6 + ')'">
        <div class="container">
            <div fxLayout="row" fxLayoutAlign="space-around start" style="padding-bottom:25px;">
                <div fxLayout="row" fxLayoutAlign="space-around start" fxFlex="100">
                    <div [fxFlex]="images.customField16" fxLayoutGap="10px"
                        *ngFor="let images of item.images; let i = index">

                        <!-- First Section: School Information -->
                        <div fxLayout="column" fxFlex="100" fxLayoutAlign="start start" fxLayoutGap="10px">
                            <h3>{{ images.customField15 }} </h3>
                            <div *ngIf="images.customField3== 'textwithicon'">
                                <!-- <p>{{ item.description }}</p> -->
                                <ul>
                                    <li *ngFor="let subitem of images.subChild; let j = index"
                                        [style.color]="item.customField11" class="bottonborder list-item"
                                        fxLayoutAlign="start start">
                                        
                                        <mat-icon *ngIf="subitem.customField20" class="thumb new-icon-size" >{{ subitem.customField20
                                            }}</mat-icon>
                                        <a class="list-link" [href]="subitem.customField21">{{ subitem.customField19
                                            }}</a>
                                    </li>
                                </ul>
                                <div *ngIf="images.customField13== '0'">
                                    <calsoft-subscriber-button></calsoft-subscriber-button>
                                </div>

                                <div *ngIf="images.customField12== '0'">
                                    <div>
                                        <span fxFlex="100" *ngFor="let item of footerIconData; let i = index">

                                            <button (click)="navigate(item)" mat-icon-button>

                                               <mat-icon [svgIcon]="item.iconName"> </mat-icon>
                                            </button>
                                        </span>

                                    </div>
                                </div>
                            </div>
                            <div *ngIf="images.customField3== 'text'" fxFlex="100" fxLayout="column" fxLayoutGap="20px">
                                <div fxFlex="100%" fxLayoutAlign="start start">
                                    <ul fxLayout="row" fxLayoutWrap fxLayoutAlign="start start">
                                        <ng-container *ngFor="let subGroup of groupItems(images.subChild, 7)">
                                            <div fxFlex="100%" fxLayout="column" fxLayoutAlign="start start">
                                                <li fxLayout="row" [style.color]="item.customField11" class="list-item"
                                                    *ngFor="let subitem of subGroup">
                                                    <mat-icon *ngIf="subitem.customField20" class="thumb new-icon-size">{{
                                                        subitem.customField20 }}</mat-icon>
                                                    <a class="list-link" [href]="subitem.customField21">{{
                                                        subitem.customField19 }}</a>
                                                </li>
                                            </div>
                                        </ng-container>
                                    </ul>


                                </div>
                                <div *ngIf="images.customField13== '0'">
                                    <div *ngIf="item.customField14== '0'">
                                        <calsoft-subscriber-button></calsoft-subscriber-button>
                                    </div>
                                    <div *ngIf="item.customField14== '1'">
                                        <calsoft-subscriber-button3></calsoft-subscriber-button3>
                                    </div>
                                </div>

                                <div>
                                    <div *ngIf="images.customField18== '1'">
                                        <img src="assets/images/playstore.png" alt="playstoreApp" class="playstoreimage"
                                            [attr.onclick]="'window.open(\'' + images.customField20 + '\', \'_blank\')'">
                                    </div>
                                    <div *ngIf="images.customField19== '1'">
                                        <img src="assets/images/appstore.png" alt="playstoreApp" class="playstoreimage"
                                            [attr.onclick]="'window.open(\'' + images.customField21 + '\', \'_blank\')'">
                                    </div>
                                </div>

                                <div *ngIf="images.customField12== '0'">

                                    <div>
                                        <span fxFlex="100" *ngFor="let item of footerIconData; let i = index">

                                            <button (click)="navigate(item)" mat-icon-button>

                                                <mat-icon [svgIcon]="item.iconName"> </mat-icon>
                                            </button>
                                        </span>

                                    </div>
                                </div>

                            </div>
                            <div *ngIf="images.customField3== 'image'" fxFlex="100">
                                <div fxLayout="column" fxLayoutGap="5px">
                                    <div fxLayout="row wrap">
                                        <div fxLayout="row" fxLayoutAlign="start start"
                                            *ngFor="let subitem of images.subChild; let j = index">
                                            <div fxLayout="row wrap">
                                                <a fxLayoutAlign="start start"
                                                    (click)="subitem.navigation==0 ? imageNavigation(subitem.catId) : link(subitem.navigatedUrl)">
                                                    <img [src]="bannerServerPath+subitem.customField6"
                                                        alt="Junior Campus" [style.height]="subitem.customField5"
                                                        class="img-responsive">
                                                </a>
                                            </div>

                                            <!-- Conditionally display "View more" for the last image only -->
                                            <!-- <a *ngIf="j === images.subChild.length - 1" (click)="img.navigation==0 ? imageNavigation(img.catId) : link(img.navigatedUrl)" fxLayoutAlign="start start"
                                                class="view-more">View more&nbsp;
                                                <mat-icon style="color: red;" class="thumb">chevron_right</mat-icon>
                                            </a> -->
                                        </div>
                                    </div>
                                    <a [style.color]="item.customField11" [href]="images.customField17"
                                        fxLayoutAlign="start start" class="view-more">View more&nbsp;
                                        <mat-icon style="color:var(--primary-color);"
                                            class="thumb">chevron_right</mat-icon>
                                    </a>
                                </div>
                                <div *ngIf="images.customField13== '0'">
                                    <div *ngIf="item.customField14== '0'">
                                        <calsoft-subscriber-button></calsoft-subscriber-button>
                                    </div>
                                    <div *ngIf="item.customField14== '1'">
                                        <calsoft-subscriber-button3></calsoft-subscriber-button3>
                                    </div>
                                </div>

                                <div>
                                    <div *ngIf="images.customField18== '1'">
                                        <img src="assets/images/playstore.png" alt="playstoreApp" class="playstoreimage"
                                            [attr.onclick]="'window.open(\'' + images.customField20 + '\', \'_blank\')'">
                                    </div>
                                    <div *ngIf="images.customField19== '1'">
                                        <img src="assets/images/appstore.png" alt="playstoreApp" class="playstoreimage"
                                            [attr.onclick]="'window.open(\'' + images.customField21 + '\', \'_blank\')'">
                                    </div>
                                </div>

                                <div *ngIf="images.customField12== '0'">

                                    <div>
                                        <span fxFlex="100" *ngFor="let item of footerIconData; let i = index">

                                            <button (click)="navigate(item)" mat-icon-button>

                                                <mat-icon [svgIcon]="item.iconName"> </mat-icon>
                                            </button>
                                        </span>

                                    </div>
                                </div>
                            </div>
                            <div *ngIf="images.customField3== 'textwithimage'" fxLayout="column"
                                fxLayoutAlign="start start">
                                <!-- <h3 style="margin-bottom:20px ;">Principal's Message</h3> -->
                                <div fxLayout="row" *ngIf="images.customField30== '0'">
                                    <div style="width:350px ;">
                                        <p [style.color]="item.customField11"><img
                                                [src]="serverPath+images.customField24" alt="images"
                                                [style.height]="images.customField5"
                                                style="float: left; margin-right: 10px;">
                                            {{ images.customField1 }}
                                        </p>
                                    </div>
                                </div>
                                
                                <div fxLayout="column" *ngIf="images.customField30== '1'">
                                    <div fxLayout="column" style="width:350px ;">
                                        <img [src]="serverPath+images.customField24" alt="images"
                                            [style.height]="images.customField5" [style.width]="images.customField11"
                                            style="float: left; margin-right: 10px;">
                                        <p [style.color]="item.customField11" style="line-height: 29px;">
                                            {{ images.customField1 }}
                                        </p>
                                    </div>
                                </div>
                                <div *ngIf="images.customField13== '0'">
                                    <div *ngIf="item.customField14== '0'">
                                        <calsoft-subscriber-button></calsoft-subscriber-button>
                                    </div>
                                    <div *ngIf="item.customField14== '1'">
                                        <calsoft-subscriber-button3></calsoft-subscriber-button3>
                                    </div>
                                </div>

                                <div>
                                    <div *ngIf="images.customField18== '1'">
                                        <img src="assets/images/playstore.png" alt="playstoreApp" class="playstoreimage"
                                            [attr.onclick]="'window.open(\'' + images.customField20 + '\', \'_blank\')'">
                                    </div>
                                    <div *ngIf="images.customField19== '1'">
                                        <img src="assets/images/appstore.png" alt="playstoreApp" class="playstoreimage"
                                            [attr.onclick]="'window.open(\'' + images.customField21 + '\', \'_blank\')'">
                                    </div>
                                </div>

                            <div *ngIf="images.customField12== '0'">

                                    <div>
                                        <span fxFlex="100" *ngFor="let item of footerIconData; let i = index">
                                            
                                            <button (click)="navigate(item)" mat-icon-button  class="custom-icon">
                                 
                                                <ng-container *ngIf="isFontAwesomeIcon(item.iconName); else materialIcon">
                                                    <i [ngClass]="getFontAwesomeClass(item.iconName)" class="icon" ></i>
                                                </ng-container>
                                                <ng-template #materialIcon>
                                                    <mat-icon [svgIcon]="item.iconName"class="icon" ></mat-icon>
                                                </ng-template>
                                            </button>
                                        </span>
                                        

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <div *ngIf="item.customField13== '1'" fxLayout="column" fxLayoutAlign="space-between center"
                class="contact-container">
                <div fxLayout="row" style="width: 100%;">
                    <mat-divider horizontal="true" class="divider1"></mat-divider>
                    <div class="contact-header" [style.font-size]="item.customField15"
                        [style.color]="item.customField17">Contact Information</div>
                    <mat-divider horizontal="true" class="divider1"></mat-divider>
                </div>

                <div fxLayout="row" [style.color]="item.customField11" class="contact-content">
                    <div fxLayout="row" fxLayoutGap="10px" fxFlex="20" class="contact-item">
                        <mat-icon>call</mat-icon>
                        <p [style.font-size]="item.customField18">{{ data.storePhoneNumber }}</p>
                    </div>
                    <div fxLayout="row" fxLayoutGap="10px" fxFlex="30" class="contact-item">
                        <mat-icon>mail</mat-icon>
                        <p [style.font-size]="item.customField18">{{data.smtpUsername}}</p>
                    </div>
                    <div fxLayout="row" fxLayoutGap="10px" fxFlex="50" class="contact-item">
                        <mat-icon style="overflow: visible;">location_on</mat-icon>
                        <p [style.font-size]="item.customField18">{{data.streetAddress}}</p>
                    </div>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between center" [style.color]="item.customField11">
                <div fxFlex *ngIf="data.dspeedupNavigationEnable == 1">
                    <a class="underline-on-hover" target="_blank" href="https://dspeedup.com/">Powered by dSPEEDUp</a>
                </div>
                <div *ngIf="data.vendorEnabled == 1">

                    <a class="border padding-10" (click)="Supplierlogin()" href="javascript:void(0)">Become a
                        Supplier</a>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="center center" [style.color]="item.customField11">
                <div fxLayoutAlign="center center">
                    <span> © {{currentYear}} {{data.storeName}}, All rights reserved.</span>
                </div>

            </div>

        </div>
    </div>

</div>



<div fxHide fxShow.lt-md>
    <div style="padding: 40px 0px;" [style.background-color]="item.customField8"
        [style.backgroundImage]="'url(' + serverPath+item.customField6 + ')'">
        <div class="container">
            <div fxLayoutGap="20px" style="padding-bottom:50px ;margin-left: 10px;">
                <div fxLayout.xs="row wrap" fxLayoutAlign="space-between start" [fxFlex]="item.customField7"
                    fxLayoutGap="20px">
                    <div *ngFor="let images of item.images; let i = index" style="padding-bottom:10px ;">

                        <!-- First Section: School Information -->
                        <div fxLayout="column" fxFlex="100" fxLayoutAlign="start start" fxLayoutGap="10px">
                            <h3>{{ images.customField15 }} </h3>
                            <div *ngIf="images.customField3== 'textwithicon'">
                                <!-- <p>{{ item.description }}</p> -->
                                <ul>
                                    <li [style.color]="item.customField11"
                                        *ngFor="let subitem of images.subChild; let j = index"
                                        class="bottonborder list-item" fxLayoutAlign="start start">
                                        <mat-icon *ngIf="subitem.customField20" class="thumb">{{ subitem.customField20
                                            }}</mat-icon>
                                        <a class="list-link" [href]="subitem.customField21">{{ subitem.customField19
                                            }}</a>
                                    </li>
                                </ul>
                                <div *ngIf="images.customField13== '0'">
                                    <div *ngIf="item.customField14== '0'">
                                        <calsoft-subscriber-button></calsoft-subscriber-button>
                                    </div>
                                    <div *ngIf="item.customField14== '1'">
                                        <calsoft-subscriber-button3></calsoft-subscriber-button3>
                                    </div>
                                </div>

                                <div>
                                    <div *ngIf="images.customField18== '1'">
                                        <img src="assets/images/playstore.png" alt="playstoreApp" class="playstoreimage"
                                            [attr.onclick]="'window.open(\'' + images.customField20 + '\', \'_blank\')'">
                                    </div>
                                    <div *ngIf="images.customField19== '1'">
                                        <img src="assets/images/appstore.png" alt="playstoreApp" class="playstoreimage"
                                            [attr.onclick]="'window.open(\'' + images.customField21 + '\', \'_blank\')'">
                                    </div>
                                </div>

                                <div *ngIf="images.customField12== '0'">

                                    <div>
                                        <span fxFlex="100" *ngFor="let item of footerIconData; let i = index">

                                            <button (click)="navigate(item)" mat-icon-button>

                                                <mat-icon [svgIcon]="item.iconName"> </mat-icon>
                                            </button>
                                        </span>

                                    </div>
                                </div>
                            </div>
                            <div *ngIf="images.customField3== 'text'" fxFlex="100" fxLayout="column" fxLayoutGap="20px">
                                <div fxFlex="100" fxLayoutAlign="start start">
                                    <ul fxLayout="row" fxLayoutWrap fxLayoutAlign="start start">
                                        <ng-container *ngFor="let subGroup of groupItems(images.subChild, 6)">
                                            <div fxFlex="100%" fxLayout="column" fxLayoutAlign="start start">
                                                <li [style.color]="item.customField11" fxLayout="row" class="list-item"
                                                    *ngFor="let subitem of subGroup">
                                                    <mat-icon *ngIf="subitem.customField20"
                                                        style="color:var(--primary-color);" class="thumb">{{
                                                        subitem.customField20 }}</mat-icon>
                                                    <a class="list-link" [href]="subitem.customField21">{{
                                                        subitem.customField19 }}</a>
                                                </li>
                                            </div>
                                        </ng-container>
                                    </ul>
                                </div>
                                <div *ngIf="images.customField13== '0'">
                                    <div *ngIf="item.customField14== '0'">
                                        <calsoft-subscriber-button></calsoft-subscriber-button>
                                    </div>
                                    <div *ngIf="item.customField14== '1'">
                                        <calsoft-subscriber-button3></calsoft-subscriber-button3>
                                    </div>
                                </div>

                                <div>
                                    <div *ngIf="images.customField18== '1'">
                                        <img src="assets/images/playstore.png" alt="playstoreApp" class="playstoreimage"
                                            [attr.onclick]="'window.open(\'' + images.customField20 + '\', \'_blank\')'">
                                    </div>
                                    <div *ngIf="images.customField19== '1'">
                                        <img src="assets/images/appstore.png" alt="playstoreApp" class="playstoreimage"
                                            [attr.onclick]="'window.open(\'' + images.customField21 + '\', \'_blank\')'">
                                    </div>
                                </div>

                                <div *ngIf="images.customField12== '0'">

                                    <div>
                                        <span fxFlex="100" *ngFor="let item of footerIconData; let i = index">

                                            <button (click)="navigate(item)" mat-icon-button>

                                                <mat-icon [svgIcon]="item.iconName"> </mat-icon>
                                            </button>
                                        </span>

                                    </div>
                                </div>

                            </div>
                            <div *ngIf="images.customField3== 'image'" fxFlex="100">
                                <div fxLayout="column" fxLayoutGap="5px">
                                    <div fxLayout="row wrap">
                                        <div fxLayout="row" fxLayoutAlign="start start"
                                            *ngFor="let subitem of images.subChild; let j = index">
                                            <div fxLayout="row wrap">
                                                <a fxLayoutAlign="start start"
                                                    (click)="subitem.navigation==0 ? imageNavigation(subitem.catId) : link(subitem.navigatedUrl)">
                                                    <img [src]="bannerServerPath+subitem.customField6"
                                                        alt="Junior Campus" [style.height]="subitem.customField5"
                                                        class="img-responsive">
                                                </a>
                                            </div>

                                            <!-- Conditionally display "View more" for the last image only -->
                                            <!-- <a *ngIf="j === images.subChild.length - 1" (click)="img.navigation==0 ? imageNavigation(img.catId) : link(img.navigatedUrl)" fxLayoutAlign="start start"
                                                class="view-more">View more&nbsp;
                                                <mat-icon style="color: red;" class="thumb">chevron_right</mat-icon>
                                            </a> -->
                                        </div>
                                    </div>
                                    <a [style.color]="item.customField11" [href]="images.customField17"
                                        fxLayoutAlign="start start" class="view-more">View more&nbsp;
                                        <mat-icon style="color:var(--primary-color);"
                                            class="thumb">chevron_right</mat-icon>
                                    </a>
                                </div>
                                <div *ngIf="images.customField13== '0'">
                                    <div *ngIf="item.customField14== '0'">
                                        <calsoft-subscriber-button></calsoft-subscriber-button>
                                    </div>
                                    <div *ngIf="item.customField14== '1'">
                                        <calsoft-subscriber-button3></calsoft-subscriber-button3>
                                    </div>
                                </div>

                                <div>
                                    <div *ngIf="images.customField18== '1'">
                                        <img src="assets/images/playstore.png" alt="playstoreApp" class="playstoreimage"
                                            [attr.onclick]="'window.open(\'' + images.customField20 + '\', \'_blank\')'">
                                    </div>
                                    <div *ngIf="images.customField19== '1'">
                                        <img src="assets/images/appstore.png" alt="playstoreApp" class="playstoreimage"
                                            [attr.onclick]="'window.open(\'' + images.customField21 + '\', \'_blank\')'">
                                    </div>
                                </div>

                                <div *ngIf="images.customField12== '0'">

                                    <div>
                                        <span fxFlex="100" *ngFor="let item of footerIconData; let i = index">

                                            <button (click)="navigate(item)" mat-icon-button>

                                                <mat-icon [svgIcon]="item.iconName"> </mat-icon>
                                            </button>
                                        </span>

                                    </div>
                                </div>
                            </div>
                            <div *ngIf="images.customField3== 'textwithimage'" fxLayout="column"
                                fxLayoutAlign="start start">
                                <!-- <h3 style="margin-bottom:20px ;">Principal's Message</h3> -->
                                <div fxLayout="row" *ngIf="images.customField30== '0'">
                                    <div style="width:100%;">
                                        <p [style.color]="item.customField11"><img
                                                [src]="serverPath+images.customField24" alt="images"
                                                [style.height]="images.customField5"
                                                style="float: left; margin-right: 10px;">
                                            {{ images.customField1 }}
                                        </p>
                                    </div>
                                </div>
                                <div fxLayout="column" *ngIf="images.customField30== '1'">
                                    <div fxLayout="column" style="width:350px ;">
                                        <img [src]="serverPath+images.customField24" alt="images"
                                            [style.height]="images.customField5" [style.width]="images.customField11"
                                            style="float: left; margin-right: 10px;">
                                        <p class="thumb1" [style.color]="item.customField11">
                                            {{ images.customField1 }}
                                        </p>
                                    </div>
                                </div>
                                <div *ngIf="images.customField13== '0'">
                                    <div *ngIf="item.customField14== '0'">
                                        <calsoft-subscriber-button></calsoft-subscriber-button>
                                    </div>
                                    <div *ngIf="item.customField14== '1'">
                                        <calsoft-subscriber-button3></calsoft-subscriber-button3>
                                    </div>
                                </div>

                                <div>
                                    <div *ngIf="images.customField18== '1'">
                                        <img src="assets/images/playstore.png" alt="playstoreApp" class="playstoreimage"
                                            [attr.onclick]="'window.open(\'' + images.customField20 + '\', \'_blank\')'">
                                    </div>
                                    <div *ngIf="images.customField19== '1'">
                                        <img src="assets/images/appstore.png" alt="playstoreApp" class="playstoreimage"
                                            [attr.onclick]="'window.open(\'' + images.customField21 + '\', \'_blank\')'">
                                    </div>
                                </div>

                                <div *ngIf="images.customField12== '0'">

                                    <div>
                                        <span fxFlex="100" *ngFor="let item of footerIconData; let i = index">

                                            <button (click)="navigate(item)" mat-icon-button>

                                                <mat-icon [svgIcon]="item.iconName"> </mat-icon>
                                            </button>
                                        </span>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


            </div>

            <div *ngIf="item.customField13== '1'" fxLayout="column" fxLayoutAlign="space-between center"
                class="contact-container">
                <div fxLayout="row" style="width: 100%;">
                    <mat-divider horizontal="true" class="divider1"></mat-divider>
                    <div class="contact-header" [style.font-size]="item.customField16"
                        [style.color]="item.customField17">Contact Information</div>
                    <mat-divider horizontal="true" class="divider1"></mat-divider>
                </div>

                <div fxLayout="column" fxLayoutAlign="space-between start" [style.color]="item.customField11"
                    class="contact-content">
                    <div fxLayout="row" fxLayoutGap="10px" fxFlex.xs="100" class="contact-item">
                        <mat-icon>call</mat-icon>
                        <p [style.font-size]="item.customField19">{{ data.storePhoneNumber }}</p>
                    </div>
                    <div fxLayout="row" fxLayoutGap="10px" fxFlex.xs="100" class="contact-item">
                        <mat-icon>mail</mat-icon>
                        <p [style.font-size]="item.customField19">{{data.smtpUsername}}</p>
                    </div>
                    <div fxLayout="row" fxLayoutGap="10px" fxFlex.xs="100" class="contact-item">
                        <mat-icon style="overflow: visible;">location_on</mat-icon>
                        <p [style.font-size]="item.customField19">{{data.streetAddress}}</p>
                    </div>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between center" [style.color]="item.customField11">
                <div fxFlex *ngIf="data.dspeedupNavigationEnable == 1">
                    <a class="underline-on-hover" target="_blank" href="https://dspeedup.com/">Powered by dSPEEDUp</a>
                </div>
                <div *ngIf="data.vendorEnabled == 1">

                    <a class="border padding-10" (click)="Supplierlogin()" href="javascript:void(0)">Become a
                        Supplier</a>
                </div>
            </div>
            <div fxLayoutAlign="center center" [style.color]="item.customField11" style="margin-top:20px ;">
                <span> © {{currentYear}} {{data.storeName}}, All rights reserved.</span>
            </div>
        </div>

    </div>

</div>