import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-animated-scroll-review',
  templateUrl: './animated-scroll-review.component.html',
  styleUrls: ['./animated-scroll-review.component.scss']
})
export class AnimatedScrollReviewComponent implements OnInit, AfterViewInit {
  
  @ViewChild('reviewsContainer', { static: false }) reviewsContainer!: ElementRef;
  @Input() data: any;
  @Input() item: any;
  @Input() images: any;

  loaderHeight: number = 0;
  totalItems: number = 0;
  viewedItems: number = 0;
  public serverPath1 = environment.commonImageApi + '/home/';
  isMobile = false;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    console.log("ngOnInit called");
    this.updateColorsBasedOnData();

    this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall]).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  changeTheme(bgColor: string, textColor: string, textColorTwo: string) {
    console.log("Applying Colors:", bgColor, textColor, textColorTwo);
    
    const commonVarElement = this.el.nativeElement.querySelector('.common-var');
    
    if (commonVarElement) {
      this.renderer.setStyle(commonVarElement, '--bg-color', bgColor);
      this.renderer.setStyle(commonVarElement, '--text-color', textColor);
      this.renderer.setStyle(commonVarElement, '--texttwo-color', textColorTwo);
    } else {
      console.error("Element with class .common-var not found!");
    }
  }

  updateColorsBasedOnData() {
    if (this.item) {
      this.changeTheme(this.item.customField25, this.item.customField29, this.item.customField30);
    } else {
      console.error("Item is null, cannot update colors.");
    }
  }

  ngAfterViewInit() {
    console.log("ngAfterViewInit called");
    this.updateColorsBasedOnData();

    setTimeout(() => {
      this.totalItems = this.el.nativeElement.querySelectorAll('.review-item').length;
    }, 500);

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.2
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.renderer.addClass(entry.target, 'animate-review');
        } else {
          this.renderer.removeClass(entry.target, 'animate-review');
        }
      });
    }, options);

    if (this.reviewsContainer) {
      const reviewItems = this.reviewsContainer.nativeElement.querySelectorAll('.review-item');
      reviewItems.forEach((item: Element) => observer.observe(item));
    }
  }

  @HostListener('window:scroll', [])
  onScroll() {
    let viewed = 0;
    const reviewItems = this.el.nativeElement.querySelectorAll('.review-item');

    reviewItems.forEach((item) => {
      if (this.isElementInView(item as HTMLElement)) {
        viewed++;
      }
    });

    this.viewedItems = viewed;
    this.updateLoader();
  }

  isElementInView(el: HTMLElement): boolean {
    const rect = el.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    return rect.top < windowHeight && rect.bottom > 0;
  }

  updateLoader() {
    this.loaderHeight = (this.viewedItems / this.totalItems) * 100;
    const loaderLine = this.el.nativeElement.querySelector('.loader-line') as HTMLElement;
    if (loaderLine) {
      this.renderer.setStyle(loaderLine, 'height', `${this.loaderHeight}%`);
    }
  }
}
