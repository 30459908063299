import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Constant } from 'src/app/constants/constant';
import { AboutService } from 'src/app/services/about.service';

@Component({
  selector: 'calsoft-subscriber-button3',
  templateUrl: './subscriber-button3.component.html',
  styleUrls: ['./subscriber-button3.component.scss']
})
export class SubscriberButton3Component implements OnInit {
  firstFormGroup: UntypedFormGroup;
  constructor( public formBuilder: UntypedFormBuilder,
    public snackBar: MatSnackBar,
    private aboutService: AboutService,) { }

  ngOnInit(): void {
    this.firstFormGroup = this.formBuilder.group({
      'subscriberId': ['',],
      'storeId': ['',],
      'changeStatusAt': ['',],
      'customerId': ['',],
      'subscriberEmail': ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
      'subscriberStatus': ['',],
      'subscriberConfirmCode': ['',],
    });
  }
  subscribe() {
    this.firstFormGroup.value.storeId = 1;
    this.firstFormGroup.value.subscriberStatus = 1;
    this.firstFormGroup.value.operationType = "New";

    if(this.firstFormGroup.valid){
      this.aboutService.subscriper(this.firstFormGroup.value).subscribe(
        response => {
          if (response['status_code'] == Constant.RESPONSE_SUCCESS) {
            this.snackBar.open(response['status_message'], '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });
            this.firstFormGroup.reset();
          } else {
            this.snackBar.open(response['status_message'], '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
          }
        },
        (err) => { });
    }else{
      this.snackBar.open('Please fill out this field.', '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
    }
    
  }


}
