import { Component } from '@angular/core';

@Component({
  selector: 'calsoft-animated-scroll-review-two',
  templateUrl: './animated-scroll-review-two.component.html',
  styleUrls: ['./animated-scroll-review-two.component.scss']
})
export class AnimatedScrollReviewTwoComponent {

}
