<form [formGroup]="firstFormGroup" class="subscribe-search-form" fxLayout="row" fxFlex="100" 
  ngClass.sm="mt-2" ngClass.xs="mt-2">
  <input style="letter-spacing: 1px;width: auto;" type="text" class="text" formControlName="subscriberEmail" placeholder=" Enter Your Email "
    fxFlex>
   
      <mat-error *ngIf="firstFormGroup.hasError('subscriberEmail')">jjhv</mat-error>


    <button [disabled]="firstFormGroup.invalid"

      mat-raised-button color="accent" (click)="subscribe()" type="button"
      class="text-muted">
    &nbsp;Subscribe</button>

  </form>
