<div class="custom-carousel" *ngIf="item.customField23=='1'">
  <owl-carousel-o [options]="LogoSlider" #owlCar class="no-arrow">
    <ng-container *ngFor="let  brand of brands; let l = index">

      <ng-template carouselSlide>
        <div>
          <img fxFlex="100" (click)="brand.navigation==0?goToCategories(brand.catId):link(brand.navigatedUrl)"
            [alt]="brand.altImage" [src]="bannerServerPath+brand.img" [ngStyle.lt-md]="{'height': item.heightMob}"
            [style.height]="item.height">
        </div>
        <div [ngStyle]="{'display': item.customField12 && item.customField12.length === 0 ? 'none' : 'block'}">

          <div class="padding-10" *ngIf="item.customField13==1 && item.customField12" fxLayoutAlign="center center">
            <button (click)="brand.navigation==0?goToCategories(brand.catId):link(brand.navigatedUrl)"
              [style.color]="'#ffffff'" [style.border-radius]="item.customField11" class="primary" mat-flat-button>
              {{item.customField12}}
            </button>
          </div>
          <div class="padding-10" *ngIf="item.customField13==0  && item.customField12" fxLayoutAlign="start center">
            <button (click)="brand.navigation==0?goToCategories(brand.catId):link(brand.navigatedUrl)"
              [style.color]="'#ffffff'" [style.border-radius]="item.customField11" class="primary" mat-flat-button>
              {{item.customField12}}
            </button>
          </div>
          <div class="padding-tb10" *ngIf="item.customField13==2  && item.customField12">
            <button fxFlex (click)="brand.navigation==0?goToCategories(brand.catId):link(brand.navigatedUrl)"
              [style.color]="'#ffffff'" [style.border-radius]="item.customField11" class="primary" mat-flat-button>
              {{item.customField12}}
            </button>
          </div>
        </div>

      </ng-template>
    </ng-container>
  </owl-carousel-o>
  <div class="custom-nav" fxShow fxHide.lt-md>
    <div class="owl-prev" (click)="owlCarousel.prev()">
      <mat-icon class="custom-icon primary-icon">keyboard_arrow_left</mat-icon>
    </div>
    <div class="owl-next" (click)="owlCarousel.next()">
      <mat-icon class="custom-icon primary-icon">keyboard_arrow_right</mat-icon>
    </div>
  </div>
</div>


<div class="custom-carousel" *ngIf="item.customField23=='2'">
  <owl-carousel-o [options]="LogoSlider" #owlCar class="no-arrow">
    <ng-container *ngFor="let  brand of brands; let l = index">
      <ng-template carouselSlide>
        <div style="box-shadow: 5px 10px 15px 0 rgba(0,0,0,.1);" contentAnim [aniName]="item.customField36">
          <img fxFlex="100" (click)="brand.navigation==0?goToCategories(brand.catId):link(brand.navigatedUrl)"
            [alt]="brand.altImage" [src]="bannerServerPath+brand.img" [ngStyle.lt-md]="{'height': item.heightMob}"
            [style.height]="item.height">
        </div>
        <div [ngStyle]="{'display': item.customField12 && item.customField12.length === 0 ? 'none' : 'block'}">

          <div class="padding-10" *ngIf="item.customField13==1" fxLayoutAlign="center center">
            <button (click)="brand.navigation==0?goToCategories(brand.catId):link(brand.navigatedUrl)"
              [style.color]="'#ffffff'" [style.border-radius]="item.customField11" class="primary" mat-flat-button>
              {{item.customField12}}
            </button>

          </div>
          <div class="padding-10" *ngIf="item.customField13==0" fxLayoutAlign="start center">
            <button (click)="brand.navigation==0?goToCategories(brand.catId):link(brand.navigatedUrl)"
              [style.color]="'#ffffff'" [style.border-radius]="item.customField11" class="primary" mat-flat-button>
              {{item.customField12}}
            </button>
          </div>
          <div class="padding-tb10" *ngIf="item.customField13==2">
            <button fxFlex (click)="brand.navigation==0?goToCategories(brand.catId):link(brand.navigatedUrl)"
              [style.color]="'#ffffff'" [style.border-radius]="item.customField11" class="primary" mat-flat-button>
              {{item.customField12}}
            </button>
          </div>
        </div>

      </ng-template>
    </ng-container>
  </owl-carousel-o>
</div>

<div class="custom-carousel" *ngIf="item.customField23=='3'">
  <owl-carousel-o [options]="LogoSlider1" #owlCar class="no-arrow">
    <ng-container *ngFor="let  brand of brands; let l = index">
      <ng-template carouselSlide>
        <div style="margin-right: -5px;" contentAnim [aniName]="item.customField36">
          <img fxFlex="100" (click)="brand.navigation==0?goToCategories(brand.catId):link(brand.navigatedUrl)"
            [alt]="brand.altImage" [src]="bannerServerPath+brand.img" [ngStyle.lt-md]="{'height': item.heightMob}"
            [style.height]="item.height">
        </div>
        <div [ngStyle]="{'display': item.customField12 && item.customField12.length === 0 ? 'none' : 'block'}">

          <div class="padding-10" *ngIf="item.customField13==1" fxLayoutAlign="center center">
            <button (click)="brand.navigation==0?goToCategories(brand.catId):link(brand.navigatedUrl)"
              [style.color]="'#ffffff'" [style.border-radius]="item.customField11" class="primary" mat-flat-button>
              {{item.customField12}}
            </button>
          </div>
          <div class="padding-10" *ngIf="item.customField13==0" fxLayoutAlign="start center">
            <button (click)="brand.navigation==0?goToCategories(brand.catId):link(brand.navigatedUrl)"
              [style.color]="'#ffffff'" [style.border-radius]="item.customField11" class="primary" mat-flat-button>
              {{item.customField12}}
            </button>
          </div>
          <div class="padding-tb10" *ngIf="item.customField13==2">
            <button fxFlex (click)="brand.navigation==0?goToCategories(brand.catId):link(brand.navigatedUrl)"
              [style.color]="'#ffffff'" [style.border-radius]="item.customField11" class="primary" mat-flat-button>
              {{item.customField12}}
            </button>
          </div>
        </div>

      </ng-template>
    </ng-container>
  </owl-carousel-o>
  <!-- <div class="custom-nav" fxShow fxHide.lt-md>
    <div class="owl-prev" (click)="owlCarousel.prev()">
      <mat-icon class="custom-icon primary-icon">keyboard_arrow_left</mat-icon>
    </div>
    <div class="owl-next" (click)="owlCarousel.next()">
      <mat-icon class="custom-icon primary-icon">keyboard_arrow_right</mat-icon>
    </div>
  </div> -->
</div>


<div class="custom-carousel" *ngIf="item.customField23=='4'">
  <div fxLayout="column">

    <div class="padding-10">

    </div>

    <div fxLayout="row">

      <div [fxFlex]="value2">

      </div>
      <div [fxFlex]="value">
        <owl-carousel-o [options]="LogoSlider2" #owlCar class="no-arrow">
          <ng-container *ngFor="let  brand of brands; let l = index">
            <ng-template carouselSlide>
              <div style="margin-right: -5px;"  contentAnim [aniName]="item.customField36">
                <img fxFlex="100" (click)="brand.navigation==0?goToCategories(brand.catId):link(brand.navigatedUrl)"
                  [alt]="brand.altImage" [src]="bannerServerPath+brand.img" [ngStyle.lt-md]="{'height': item.heightMob}"
                  [style.height]="item.height">
              </div>
              <div [ngStyle]="{'display': item.customField12 && item.customField12.length === 0 ? 'none' : 'block'}">

                <div class="padding-10" *ngIf="item.customField13==1" fxLayoutAlign="center center">
                  <button (click)="brand.navigation==0?goToCategories(brand.catId):link(brand.navigatedUrl)"
                    [style.color]="'#ffffff'" [style.border-radius]="item.customField11" class="primary"
                    mat-flat-button>
                    {{item.customField12}}
                  </button>
                </div>
                <div class="padding-10" *ngIf="item.customField13==0" fxLayoutAlign="start center">
                  <button (click)="brand.navigation==0?goToCategories(brand.catId):link(brand.navigatedUrl)"
                    [style.color]="'#ffffff'" [style.border-radius]="item.customField11" class="primary"
                    mat-flat-button>
                    {{item.customField12}}
                  </button>
                </div>
                <div class="padding-tb10" *ngIf="item.customField13==2">
                  <button fxFlex (click)="brand.navigation==0?goToCategories(brand.catId):link(brand.navigatedUrl)"
                    [style.color]="'#ffffff'" [style.border-radius]="item.customField11" class="primary"
                    mat-flat-button>
                    {{item.customField12}}
                  </button>
                </div>
              </div>

            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>

      <div [fxFlex]="value2">

      </div>
    </div>
  </div>

</div>

<div class="custom-carousel" *ngIf="item.customField23=='5'">
  <owl-carousel-o [options]="LogoSlider1" #owlCar class="no-arrow">
    <ng-container *ngFor="let  brand of brands; let l = index">
      <ng-template carouselSlide>

        <div class="border" contentAnim [aniName]="item.customField36">
          <div class="icon-container1">
            <img (click)="brand.navigation==0?goToCategories(brand.catId):link(brand.navigatedUrl)"
              [alt]="brand.altImage" [src]="bannerServerPath+brand.img" [ngStyle.lt-md]="{'height': item.heightMob}"
              [style.height]="item.height" class="topborder">
          </div>
          <div class="text-container">
            <div class="padding-10">
              <h3 [ngStyle.gt-md]="{'font-size': item.customField34 }" [ngStyle.lt-md]="{
                  
                  'font-size': item.customField35
              }">{{brand.customField20}}</h3>

              <!-- <p class="text" [innerHTML]="brand.customField21"></p> -->
              <div [ngStyle.gt-md]="{ 'height': item.customField37 }">
                <calsoft-text-editor class="text" [data]="data" [text]="brand.customField21"
                  [ngStyle.gt-md]="{'font-size': item.customField34 }" [ngStyle.lt-md]="{
                  
                  'font-size': item.customField35
              }">
                </calsoft-text-editor>
              </div>

            </div>

            <!-- <hr style="background-color: #e6f6ff;margin-top: 10px;"/> -->

            <div *ngIf="item.customField13==1" fxLayoutAlign="center center">
              <p (click)="brand.navigation==0?goToCategories(brand.catId):link(brand.navigatedUrl)"
                class="para-css primary-icon" [ngStyle.gt-md]="{'font-size': item.customField34 }" [ngStyle.lt-md]="{
                    
                    'font-size': item.customField35
                }">
                {{item.customField12}} </p>&#160;<mat-icon style="margin-top: 10px;
                    font-size: 17px ;">arrow_forward</mat-icon>
            </div>

            <div *ngIf="item.customField13==0" fxLayoutAlign="start center">
              <p (click)="brand.navigation==0?goToCategories(brand.catId):link(brand.navigatedUrl)"
                class="para-css primary-icon" [ngStyle.gt-md]="{'font-size': item.customField34 }" [ngStyle.lt-md]="{
                    
                    'font-size': item.customField35
                }">
                {{item.customField12}} </p>&#160;<mat-icon style="margin-top: 10px;
                    font-size: 17px ;">arrow_forward</mat-icon>
            </div>

            <div *ngIf="item.customField13==2">
              <p (click)="brand.navigation==0?goToCategories(brand.catId):link(brand.navigatedUrl)"
                class="para-css primary" [ngStyle.gt-md]="{'font-size': item.customField34 }" [ngStyle.lt-md]="{
                    
                    'font-size': item.customField35
                }">
                {{item.customField12}} </p>&#160;<mat-icon style="margin-top: 10px;
                    font-size: 17px ;">arrow_forward</mat-icon>
            </div>

            <div class="padding-10">

            </div>

          </div>
        </div>


      </ng-template>
    </ng-container>
  </owl-carousel-o>


</div>

<div class="container" *ngIf="item.customField23=='6'">

  <div fxLayout="column">


    <div fxLayout="row" *ngIf="item.title.length>0" fxLayoutAlign="center center">

      <!-- <h3 class="head-black" [innerHTML]="item.title"></h3> -->
      <calsoft-text-editor class="head-black" [data]="data" [text]="item.title"
        [ngStyle.gt-md]="{'font-size': item.customField34 }" [ngStyle.lt-md]="{
          
          'font-size': item.customField35
      }">
      </calsoft-text-editor>

    </div>

    <div class="description" *ngIf="item.customField7.length>0" fxLayoutAlign="center center">
      <!-- <p class="grayTxt" [innerHTML]="item.customField7"></p> -->
      <calsoft-text-editor class="grayTxt" [data]="data" [text]="item.customField7"
        [ngStyle.gt-md]="{'font-size': item.customField34 }" [ngStyle.lt-md]="{
          
          'font-size': item.customField35
      }">
      </calsoft-text-editor>
    </div>


    <div class="padding-10">

    </div>

    <div>

      <owl-carousel-o [options]="LogoSlider" #owlCar class="no-arrow">
        <ng-container *ngFor="let  brand of brands; let l = index">
          <ng-template carouselSlide>

            <div class="icon-container1 border-image">
              <img (click)="brand.navigation==0?goToCategories(brand.catId):link(brand.navigatedUrl)"
                [alt]="brand.altImage" [src]="bannerServerPath+brand.img" [ngStyle.lt-md]="{'height': item.heightMob}"
                [style.height]="item.height" [style.width]="item.customField25" class="topborder"
                style="border-radius: 12px;">
              <div class="brand-name">{{brand.customField20}}</div>
            </div>

          </ng-template>
        </ng-container>
      </owl-carousel-o>

    </div>

    <div class="padding-10">

    </div>

  </div>

</div>

<div class="custom-carousel" *ngIf="item.customField23=='7'">
  <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
    <div fxFlex="30">
      <div fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="start start">
          <!-- <h3 [innerHTML]="item.title"></h3> -->
          <calsoft-text-editor [data]="data" [text]="item.title" [ngStyle.gt-md]="{'font-size': item.customField34 }"
            [ngStyle.lt-md]="{
              
              'font-size': item.customField35
          }">
          </calsoft-text-editor>
        </div>
        <div class="description" fxLayoutAlign="start start">
          <!-- <p style="text-align: left;" [innerHTML]="item.customField7"> -->
          <calsoft-text-editor style="text-align: left;" [data]="data" [text]="item.customField7"
            [ngStyle.gt-md]="{'font-size': item.customField34 }" [ngStyle.lt-md]="{
                
                'font-size': item.customField35
            }">
          </calsoft-text-editor>

        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
          <b class="spacetab"><a style="text-decoration: underline; cursor: pointer;">{{item.customField12}}</a></b>
        </div>
      </div>
    </div>

    <div fxFlex="70">
      <owl-carousel-o [options]="LogoSlider" #owlCar class="no-arrow">
        <ng-container *ngFor="let  brand of brands; let l = index">
          <ng-template carouselSlide>
            <div class="profile-card"  contentAnim [aniName]="item.customField36">
              <img fxFlex="100" (click)="brand.navigation==0?goToCategories(brand.catId):link(brand.navigatedUrl)"
                [alt]="brand.altImage" [src]="bannerServerPath+brand.img" [ngStyle.lt-md]="{'height': item.heightMob}"
                [style.height]="item.height" class="brand-image">

              <div class="image-title" fxLayoutAlign="center center">
                <!-- <span class="titleText" [innerHTML]="brand.customField21"></span> -->
                <calsoft-text-editor class="titleText" [data]="data" [text]="brand.customField21"
                  [ngStyle.gt-md]="{'font-size': item.customField34 }" [ngStyle.lt-md]="{
                    
                    'font-size': item.customField35
                }">
                </calsoft-text-editor>
              </div>

              <div class="hover-area alignment-center">
                <div class="profile-body">
                  <!-- <h3 class="profile-title" [innerHTML]="brand.customField21"> -->
                  <calsoft-text-editor class="profile-title" [data]="data" [text]="brand.customField21"
                    [ngStyle.gt-md]="{'font-size': item.customField34 }" [ngStyle.lt-md]="{
                        
                        'font-size': item.customField35
                    }">
                  </calsoft-text-editor>

                  <p class="profile-designation">
                  </p>
                  <p class="profile-content">
                    {{brand.customField20}}
                  </p>
                </div>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>

  </div>

</div>

<div class="custom-carousel" *ngIf="item.customField23=='9'">
  <owl-carousel-o [options]="LogoSlider" #owlCar class="no-arrow">
    <ng-container *ngFor="let  brand of brands; let l = index">
      <ng-template carouselSlide  contentAnim [aniName]="item.customField36">
        <div style=" margin: 10px;" >
          <img (click)="brand.navigation==0?goToCategories(brand.catId):link(brand.navigatedUrl)" [alt]="brand.altImage"
            [src]="bannerServerPath+brand.img" [ngStyle.lt-md]="{'height': item.heightMob}" [style.height]="item.height"
            [style.width]="item.customField25" [ngStyle.lt-md]="{'width': item.customField26}"
            style="border-radius: 8px;">
        </div>

      </ng-template>
    </ng-container>
  </owl-carousel-o>
  <div style="position: sticky;">
    <div class="shelf"></div>
  </div>
</div>


<div class="custom-carousel" *ngIf="item.customField23=='10'">

  <owl-carousel-o [options]="customOptions">
    <ng-container *ngFor="let  brand of brands; let i = index">
      <ng-template carouselSlide>
        <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center center">
          <div fxFlex="50">
            <img (click)="brand.navigation==0?goToCategories(brand.catId):link(brand.navigatedUrl)"
              [alt]="brand.altImage" [src]="bannerServerPath+brand.img" [ngStyle.lt-md]="{'height': item.heightMob}"
              [style.height]="item.height" class="brand-images">
          </div>
          <div fxFlex="50" style="text-align: left;">
            <div style="padding-left: 20px;">
              <h3 [ngStyle.gt-md]="{'font-size': item.customField34 }" [ngStyle.lt-md]="{
                  
                  'font-size': item.customField35
              }"><b>{{ brand.customField20 }}</b></h3>
              <!-- <p [innerHTML]="brand.customField21"></p> -->
              <calsoft-text-editor [data]="data" [text]="brand.customField21"
                [ngStyle.gt-md]="{'font-size': item.customField34 }" [ngStyle.lt-md]="{
                  
                  'font-size': item.customField35
              }">
              </calsoft-text-editor>
              <div style="display: flex;">
                <p style="text-transform: uppercase;color:#6ab8d9" class="brand-images"
                  (click)="brand.navigation==0?goToCategories(brand.catId):link(brand.navigatedUrl)">
                  {{item.customField12}} </p>
                <mat-icon style="margin-top: -2px;color:#6ab8d9;" *ngIf="item.customField12">chevron_right</mat-icon>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>

</div>

<div class="custom-carousel" *ngIf="item.customField23=='11'">
  <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
    <div fxFlex="100">
      <owl-carousel-o [options]="LogoSlider4" #owlCar class="no-arrow">
        <ng-container *ngFor="let  brand of brands; let l = index">
          <ng-template carouselSlide>
            <div class="profile-cards"  contentAnim [aniName]="item.customField36">

              <div class="blurimage">
                <img [alt]="brand.altImage" [src]="bannerServerPath+brand.img"
                  [ngStyle.lt-md]="{'height': item.heightMob}" [style.height]="item.height" class="brand-image-card">
                <img [src]="bannerServerPath1+brand.customField6" *ngIf="brand.customField6" class="overimg" />
              </div>

              <div class="picimage">
                <img [alt]="brand.altImage" [src]="bannerServerPath+brand.img" class="innerPic">
                <div style="padding: 10px;margin-top: 10px;">
                  <!-- <img [src]="bannerServerPath1+brand.customField7"  *ngIf="brand.customField7" class="innersubimg" style="width: 60%;height: 70px;"> -->
                  <img [src]="bannerServerPath1+brand.customField7" *ngIf="brand.customField7" class="overimg1">
                </div>
                <div style="padding: 15px;margin-top: 50px;">
                  <!-- <p [innerHTML]="brand.customField21"></p> -->
                  <calsoft-text-editor [data]="data" [text]="brand.customField21">
                  </calsoft-text-editor>
                </div>

                <div fxLayoutAlign="center center" *ngIf="item.customField12">
                  <button (click)="brand.navigation==0?goToCategories(brand.catId):link(brand.navigatedUrl)"
                    class="btntype11" mat-flat-button>
                    {{item.customField12}}
                  </button>
                </div>

              </div>

            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>

  </div>

</div>

<div class="custom-carousel" *ngIf="item.customField23=='12'">

  <div class="title padding-10">
    <!-- <h2 style="color: white;">
      {{item.title}}
    </h2> -->
  </div>

  <div class="padding-10">

  </div>

  <div>
    <owl-carousel-o [options]="LogoSlider5" #owlCar class="no-arrow">
      <ng-container *ngFor="let  brand of brands; let l = index">
        <ng-template carouselSlide contentAnim [aniName]="item.customField36">

          <div fxLayout="row" class="padding-10" fxLayout.xs="column" style="width: 100%;">

            <div fxFlex="5">

            </div>

            <div fxFlex="30">

              <img (click)="brand.navigation==0?goToCategories(brand.catId):link(brand.navigatedUrl)"
                [alt]="brand.altImage" [src]="bannerServerPath+brand.img" [ngStyle.lt-md]="{'height': item.heightMob}"
                [style.height]="item.height">

            </div>

            <div fxFlex="5">

            </div>

            <div fxFlex="50" [style.margin-top]="item.gapBetweenImage">

              <div class="description text-color">

                <calsoft-text-editor [style.color]="item.customField5" style="text-align: left;" [data]="data"
                  [text]="brand.customField21" [ngStyle.gt-md]="{'font-size': item.customField34 }" [ngStyle.lt-md]="{
                    
                    'font-size': item.customField35
                }">
                </calsoft-text-editor>
                <!-- <p style="text-align: left;" [innerHTML]="brand.customField21">
                </p> -->
              </div>

              <div class="subDescrption text-color">
                <!-- <p style="text-align: left;"></p> -->
                <calsoft-text-editor [style.color]="item.customField5" style="text-align: left;" [data]="data"
                  [text]="brand.customField20" [ngStyle.gt-md]="{'font-size': item.customField34 }" [ngStyle.lt-md]="{
                      
                      'font-size': item.customField35
                  }">
                </calsoft-text-editor>


                <!-- <p class="text-color" style="text-align: left;"> -->
                <calsoft-text-editor [style.color]="item.customField5" style="text-align: left;" [data]="data"
                  [text]="brand.customField8" [ngStyle.gt-md]="{'font-size': item.customField34 }" [ngStyle.lt-md]="{
                      
                      'font-size': item.customField35
                  }">
                </calsoft-text-editor>


              </div>

              <div *ngIf="brand.customField9.length>0" style="align-items: start;margin-top: 15px;" class="padding-10">
                <p class="btn-video btn-iframe" (click)="showModal(brand.customField9)">
                  <mat-icon>play_arrow</mat-icon>
                </p>
              </div>
            </div>
          </div>

        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>

</div>

<div *ngIf="item.customField23=='13'">

  <div fxLayout="row" fxHide fxShow.lt-md  contentAnim [aniName]="item.customField36">
    <div fxFlex="50">
      <h3 style="text-align: left;font-weight: bold;margin-left: 10px;"
        [ngStyle.gt-md]="{'font-size': item.customField34 }" [ngStyle.lt-md]="{
          
          'font-size': item.customField35
      }">{{item.title}}</h3>
    </div>
  </div>

  <div class="custom-carousel" contentAnim [aniName]="item.customField36">
    <div fxHide.lt-md class="test-backgroundimg"
      [style.backgroundImage]="'url(' + bannerServerPath1+item.customField7 + ')'"></div>

    <div class="testimonial-background" [style.backgroundImage]="'url(' + bannerServerPath1+item.customField6 + ')'">
    </div>
    <div fxLayout="row" fxHide.lt-md>
      <div fxFlex="8"></div>
      <div fxFlex="20">
        <h3 style="text-align: left;font-weight: bold;" [ngStyle.gt-md]="{'font-size': item.customField34 }"
          [ngStyle.lt-md]="{
            
            'font-size': item.customField35
        }">{{item.title}}</h3>
      </div>
    </div>

    <div class="padding-10">

    </div>

    <div style="text-align: right;margin-right: 10%;">
      <button mat-icon-button (click)="owlElement.prev()" class="navbtn">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <button mat-icon-button (click)="owlElement.next()" class="navbtn">
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>

    <div style="width: 84%;margin-left: auto;margin-right: auto;">
      <owl-carousel-o [options]="LogoSlider6" #owlElement>
        <ng-container *ngFor="let  brand of brands; let i = index">
          <ng-template carouselSlide contentAnim [aniName]="item.customField36">
            <div class="testimonial-info">

              <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
                <div fxLayout="row" fxLayoutAlign="center center" fxLayout.sm="column" fxLayout.xs="column">
                  <div class="testi-img">
                    <img [src]="bannerServerPath+brand.img" [alt]="brand.altImage" class="testi-imgz">
                  </div>
                  <div class="contentslides">
                    <h6>{{brand.customField20}}</h6>
                    <p>{{brand.customField8}}</p>
                  </div>
                </div>
              </div>
              <p style="text-align: left;margin-top: 15px;">{{brand.customField21}}</p>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
  </div>
</div>


<div *ngIf="item.customField23=='14'">
  <div class="container">
    <div fxLayout="row wrap" fxLayout.sm="column" fxLayout.xs="column">
      <div fxFlex="50" fxFlex.sm="100" contentAnim [aniName]="item.customField36" *ngFor="let brand of brands.slice(0, loadedImages)">
        <div class="image-gap"
          [style.margin-right.px]="(i % 2 !== 0 && i !== brands.length - 1) ? 'item.gapBetweenImage' : '0'">
          <img style="width: 100%;" (click)="brand.navigation==0?goToCategories(brand.catId):link(brand.navigatedUrl)"
            [alt]="brand.altImage" [src]="bannerServerPath+brand.img" [ngStyle.lt-md]="{'height': item.heightMob}"
            [style.height]="item.height">
        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="item.customField23=='25'">
  <div fxHide.lt-md>
    <div fxLayout="row" style="padding-top: 40px;padding-bottom: 50px;" contentAnim [aniName]="item.customField36">
      <div fxFlex="16" style="padding-left: 32px;">
        <div style="padding: 140px 20px 20px;">
          <div class="static-title-icon"></div>
          <div>
            <p style="margin: 0px 0px 16px;padding: 15px 0px 0px;font-size: 25px;color: white;text-align: left;">
              <b>
                <calsoft-text-editor [data]="data" [text]="item.title"
                  [ngStyle.gt-md]="{'font-size': item.customField34 }" [ngStyle.lt-md]="{
                    
                    'font-size': item.customField35
                }">
                </calsoft-text-editor>
              </b>
            </p>
          </div>
        </div>
      </div>
      <div fxFlex="83">
        <owl-carousel-o [options]="LogoSlider7" #owlElement>
          <ng-container *ngFor="let  brand of brands; let i = index">
            <ng-template carouselSlide>


              <div fxLayout="row">

                <div fxLayout="column" fxFlex style="background:white; border-radius: 10px;">
                  <div fxLayout="row" fxLayoutAlign="center center" [style.background-color]="brand.customField5"
                    style="height: 140px; border-top-left-radius: 10px;border-top-right-radius: 10px;">

                    <img style="width: 200px;
                    margin: auto;" class="img1" [src]="bannerServerPath + brand.img" [alt]="brand.altImage">


                  </div>
                  <div fxLayout="row">
                    <div
                      style="  margin-left: 20px; margin-top: -15px; height: 35px; width: 35px; border-radius: 50%; background: #006ada;">
                      <img src="/assets/images/others/client-quote.png"
                        style="padding-top: 10px; width: 16px; height: 16px; padding-left: 9px;">
                    </div>

                  </div>

                  <div fxLayout="row" [style.height]="item.height" style="height: 300px;padding:5px 20px; ">
                    <p>
                      <calsoft-text-editor [data]="data" [text]="brand.customField20"
                        [ngStyle.gt-md]="{'font-size': item.customField34 }" [ngStyle.lt-md]="{
                          
                          'font-size': item.customField35
                      }">
                      </calsoft-text-editor>
                    </p>

                  </div>

                  <div fxLayout="column" style="height: auto;padding:5px 20px;">
                    <p>
                      <calsoft-text-editor [data]="data" [text]="brand.customField21"
                        [ngStyle.gt-md]="{'font-size': item.customField34 }" [ngStyle.lt-md]="{
                          
                          'font-size': item.customField35
                      }">
                      </calsoft-text-editor>
                    </p>
                    <p>
                      <calsoft-text-editor [data]="data" [text]="brand.customField9"
                        [ngStyle.gt-md]="{'font-size': item.customField34 }" [ngStyle.lt-md]="{
                          
                          'font-size': item.customField35
                      }">
                      </calsoft-text-editor>
                    </p>
                  </div>

                </div>

              </div>

              <!-- <div class="testimonial-info1">                
                  <div  >
                    <div style="heigth:120px;justify-content: center; align-items: center; display: flex; border-top-left-radius: 10px; border-top-right-radius: 10px;">
                        <img  class="img1" [src]="bannerServerPath + brand.img" [alt]="brand.altImage">
                    </div>
                
                    <div class="quote-icon">
                        <img src="/assets/images/others/client-quote.png" style="padding-top: 10px; width: 16px; height: 16px; padding-left: 9px;">
                    </div>
                    
                    <p style="padding: 5px 15px 2px 20px; font-size: 15px; text-align: justify;">{{brand.customField20}}</p>              
                    <div class="contentslides" >
                        <p style="font-size: 15px;"><b>{{brand.customField21}}</b></p>
                        <p style="font-size: 13px;padding-bottom: 15px;">{{brand.customField9}}</p>
                    </div>
                </div>              
              </div> -->

            </ng-template>
          </ng-container>
        </owl-carousel-o>

        <div style="text-align: right;margin-right: 10%; margin-top: 10px;">
          <button mat-icon-button (click)="owlElement.prev()" class="navbtn">
            <mat-icon>chevron_left</mat-icon>
          </button>
          <button mat-icon-button (click)="owlElement.next()" class="navbtn">
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>

      </div>
    </div>
  </div>

  <div fxHide fxShow.lt-md>
    <div fxLayout="column" style="padding-top: 40px;padding-bottom: 50px;">
      <div fxFlex style="padding-left: 32px;">
        <div>
          <div class="static-title-icon"></div>
          <div>
            <p style="margin:15px;padding: 15px 0px 0px;font-size: 25px;color: white;text-align: left;">
              <b>
                <calsoft-text-editor [data]="data" [text]="item.title"
                  [ngStyle.gt-md]="{'font-size': item.customField34 }" [ngStyle.lt-md]="{
                    
                    'font-size': item.customField35
                }">
                </calsoft-text-editor>
              </b>
            </p>
          </div>
        </div>
      </div>
      <div fxFlex>
        <owl-carousel-o [options]="LogoSlider7" #owlElement>
          <ng-container *ngFor="let  brand of brands; let i = index">
            <ng-template carouselSlide>


              <div fxLayout="row" style="margin:20px ;"  contentAnim [aniName]="item.customField36">

                <div fxLayout="column" fxFlex style="background:white;border-radius:10px;">
                  <div fxLayout="row" fxLayoutAlign="center center" [style.background-color]="brand.customField5"
                    style="height: 140px; border-top-left-radius: 10px;border-top-right-radius: 10px;">

                    <img style="width: 200px;
                    margin: auto;" class="img1" [src]="bannerServerPath + brand.img" [alt]="brand.altImage">


                  </div>
                  <div fxLayout="row">
                    <div
                      style="  margin-left: 20px; margin-top: -15px; height: 35px; width: 35px; border-radius: 50%; background: #006ada;">
                      <img src="/assets/images/others/client-quote.png"
                        style="padding-top: 10px; width: 16px; height: 16px; padding-left: 9px;">
                    </div>

                  </div>

                  <div fxLayout="row" style="height:auto;padding:5px 20px; ">
                    <p>
                      <calsoft-text-editor [data]="data" [text]="brand.customField20"
                        [ngStyle.gt-md]="{'font-size': item.customField34 }" [ngStyle.lt-md]="{
                          
                          'font-size': item.customField35
                      }">
                      </calsoft-text-editor>
                    </p>

                  </div>

                  <div fxLayout="column" style="height: auto;padding:5px 20px;">
                    <p>
                      <calsoft-text-editor [data]="data" [text]="brand.customField21"
                        [ngStyle.gt-md]="{'font-size': item.customField34 }" [ngStyle.lt-md]="{
                          
                          'font-size': item.customField35
                      }">
                      </calsoft-text-editor>
                    </p>
                    <p>
                      <calsoft-text-editor [data]="data" [text]="brand.customField9"
                        [ngStyle.gt-md]="{'font-size': item.customField34 }" [ngStyle.lt-md]="{
                          
                          'font-size': item.customField35
                      }">
                      </calsoft-text-editor>
                    </p>
                  </div>

                </div>

              </div>

              <!-- <div class="testimonial-info1">                
                  <div  >
                    <div style="heigth:120px;justify-content: center; align-items: center; display: flex; border-top-left-radius: 10px; border-top-right-radius: 10px;">
                        <img  class="img1" [src]="bannerServerPath + brand.img" [alt]="brand.altImage">
                    </div>
                
                    <div class="quote-icon">
                        <img src="/assets/images/others/client-quote.png" style="padding-top: 10px; width: 16px; height: 16px; padding-left: 9px;">
                    </div>
                    
                    <p style="padding: 5px 15px 2px 20px; font-size: 15px; text-align: justify;">{{brand.customField20}}</p>              
                    <div class="contentslides" >
                        <p style="font-size: 15px;"><b>{{brand.customField21}}</b></p>
                        <p style="font-size: 13px;padding-bottom: 15px;">{{brand.customField9}}</p>
                    </div>
                </div>              
              </div> -->

            </ng-template>
          </ng-container>
        </owl-carousel-o>



      </div>
    </div>
  </div>

</div>


<div *ngIf="item.customField23=='21'">
  <div>
    <section class="technology-used-section ">
      <div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" class="row-layout"  contentAnim [aniName]="item.customField36">
          <!-- Card Image Container -->
          <div fxFlex="65" class="card-image-container">
            <div fxLayout="row wrap" fxLayoutAlign="start center" class="card-row">
              <div *ngFor="let brand of brands; let i = index" class="card-item" fxFlex="20"
                [fxFlex]="isMobile ? '30' : '20'" fxFlex.xs="44" fxFlex.sm="30">
                <div class="card-wrapper" style="width:100%;overflow: hidden;">
                  <!-- Fixed size for the card to maintain uniformity -->
                  <div class="card-content" style="width: 100%; height: 100%; position: relative;">
                    <img [src]="bannerServerPath + brand.img" [alt]="brand.altImage" class="card-image"
                      style="width: 100%" [style.height]="item.height" [ngStyle.lt-md]="{
                         
                          'height': item.heightMob
                           }">
                    <!-- Image covers the entire card-content container -->
                    <div class="loader"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Text Container -->
          <div fxFlex="35" class="text-container" style="padding: 10px;">
            <div class="text-content">
              <span></span>
              <h3 [innerHTML]="item.title" [ngStyle.gt-md]="{'font-size': item.customField34 }" [ngStyle.lt-md]="{
                  
                  'font-size': item.customField35
              }"></h3>
              <p [innerHTML]="item.customField7"></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>





<div *ngIf="item.customField23=='22'" [style.height]="item.height" [ngStyle.lt-md]="{'height': item.heightMob}">

  <div class="container">
    <div fxLayout="row wrap" fxLayout.xs="column" class="row-wrapper video-section"
      style="background-color: transparent;">
      <!-- Text Content Column -->
      <div fxFlex="30" fxFlex.gt-sm="40%" class="column-text-content" style="padding-top: 20px;" fxLayout="column"
        fxLayoutAlign="center center">
        <div [style.color]="item.customField5" class="text-container-card">
          <span class="welcome-message" [innerHTML]="item.title" [ngStyle.gt-md]="{'font-size': item.customField34 }"
            [ngStyle.lt-md]="{
              
              'font-size': item.customField35
          }"></span>
          <h1 class="main-heading" id="heading-style">{{item.customField21}}</h1>
          <p class="subheading description" id="description-style" [innerHTML]="item.customField7  "
            [ngStyle.gt-md]="{'font-size': item.customField34 }" [ngStyle.lt-md]="{
              
              'font-size': item.customField35
          }"></p>
          <div *ngIf="item.customField12" class="video-wrapper margin-top">
            <a href="#!" class="btn-get-started">{{item.customField12 | uppercase}}<i
                class="fa-icon fa-arrow icon-style"></i></a>
          </div>
        </div>
      </div>


      <!-- Carousel Column -->
      <div fxFlex="50" fxFlex.gt-sm="60%" class="column-carousel">
        <div class="card-carousel fade-in-right" id="carousel-style">
          <owl-carousel-o [options]="carouselOptions">
            <ng-container *ngFor="let brand of brands; let i = index">
              <ng-template carouselSlide>
                <div class="carousel-item" style="border-radius: 15px; overflow: hidden; position: relative;">
                  <div class="slide-content" style="padding: 10px;">
                    <a>
                      <div class="card-content-2"
                        style="width: 100%; position: relative; border-radius: 15px; overflow: hidden;">
                        <!-- Image container with the image -->
                        <div class="image-container" style="width: 100%; height: 100%;">
                          <img [src]="bannerServerPath + brand.img" [alt]="brand.altImage" style="width: 100%"
                            [style.height]="item.customField3" [ngStyle.lt-md]="{
                         
                              'height': item.heightMob
                               }">
                        </div>
                        <!-- Title container overlaying the image -->
                        <!-- <div class="title-container" style="padding: 5px; color: white; position: absolute; bottom: 0; left: 0; width: 100%">
                          <span >{{brand.customField20}}</span>
                        </div> -->
                      </div>
                    </a>
                  </div>
                </div>
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>
</div>




<div *ngIf="item.customField23=='27'" class="brand26" style="padding: 20px;" [ngStyle.lt-md]="{
  'height': item.heightMob,}" [ngStyle.gt-md]="{'padding': '5rem 20px',}" [style.height]="item.height"
  [style.backgroundImage]="'url(' + bannerServerPath+item.customField25 + ')'">

  <div fxLayout="column" contentAnim [aniName]="item.customField36">
    <div fxLayout="column" class="upper-contents " [style.color]="item.customField5">
      <div style="text-align: center;">
        <span [innerHTML]="sanitizeHtml(item.title)" [ngStyle.gt-md]="{'font-size': item.customField31 }"
          [ngStyle.lt-md]="{'font-size': item.customField34}"></span>
      </div>
      <div class="sub-title" style="text-align: center;">
        <span [innerHTML]="item.customField15" [ngStyle.gt-md]="{'font-size': item.customField33 }"
          [ngStyle.lt-md]="{'font-size': item.customField18}"></span>
      </div>
      <div class="description" style="text-align: center;">
        <span [innerHTML]="sanitizeHtml(item.customField7)" [ngStyle.gt-md]="{'font-size': item.customField32 }"
          [ngStyle.lt-md]="{'font-size': item.customField17}"></span>
      </div>
    </div>

    <div fxLayout="column" class="tag-contents">
      <!-- {{totallength}} -->
      <div fxLayout="row wrap" fxLayoutAlign="center">


        <div *ngFor="let image of item.images" [fxFlex]="displayWebCount" [fxFlex.lt-md]="displayMobileCount"
          class="image-container">
          <div class="image-overlay">
            <div>
              <img [style.height]="item.customField19 ||  auto " [src]="bannerServerPath+image.img"
                alt="item.altImage" />
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>


</div>



<div *ngIf="item.customField23=='29'" class="carousel-container">
  <div class="carousel-track" style="border-bottom: 1px solid #e5e7eb;" #carouselTrack contentAnim
    [aniName]="item.customField36">

    <div *ngFor="let brand of brands; let i = index" class="carousel-slide">
      <img (click)="brand.navigation == 0 ? goToCategories(brand.catId) : link(brand.navigatedUrl)"
        [alt]="brand.altImage" [src]="bannerServerPath + brand.img">
    </div>

    <!-- Duplicate slides for infinite effect -->

    <div *ngFor="let brand of brands; let i = index" class="carousel-slide">
      <img (click)="brand.navigation == 0 ? goToCategories(brand.catId) : link(brand.navigatedUrl)"
        [alt]="brand.altImage" [src]="bannerServerPath + brand.img">
    </div>

  </div>
</div>

<div *ngIf="item.customField23=='30'" class="brand26" style="padding: 20px;padding-top: 3rem;" [ngStyle.lt-md]="{
      'height': item.heightMob,
      
    }" [style.height]="item.height" [style.backgroundImage]="'url(' + bannerServerPath+item.customField25 + ')'">
  <div fxLayout="column" contentAnim [aniName]="item.customField36">
    <div fxLayout="column" class="upper-contents " [style.color]="item.customField5">
      <div style="text-align: center;">
        <span [innerHTML]="sanitizeHtml(item.title)" [ngStyle.gt-md]="{'font-size': item.customField31 }"
          [ngStyle.lt-md]="{'font-size': item.customField34}"></span>
      </div>
      <div class="sub-title" style="text-align: center;">
        <span [innerHTML]="item.customField15" [ngStyle.gt-md]="{'font-size': item.customField33 }"
          [ngStyle.lt-md]="{'font-size': item.customField18}"></span>
      </div>
      <div class="description" style="text-align: center;">
        <span [innerHTML]="sanitizeHtml(item.customField7)" [ngStyle.gt-md]="{'font-size': item.customField32 }"
          [ngStyle.lt-md]="{'font-size': item.customField17}"></span>
      </div>
    </div>
    <div fxLayout="column" class="tag-contents">

      <div fxLayout="row wrap" fxLayoutAlign="center" fxLayoutGap.xs="10px" fxLayoutGap="10px">



        <button *ngFor="let subChild of item.images; let i = index" class="tech-item" fxLayout="row " fxLayoutGap="10px" [fxFlex]="getFlexValue(i)"
          [fxFlex.lt-md]="displayMobileCount" (mouseenter)="hoverTech = subChild.customField20"
          (mouseleave)="hoverTech = null"
          [ngStyle]="{'background': hoverTech === subChild.customField20 ? subChild.customField11 : '#f1f1f1', 'color': hoverTech === subChild.customField20 ? 'white' : 'black'}">
          <div fxFlex="50">

            <img
              [src]="hoverTech === subChild.customField20 ? bannerServerPath1+subChild.customField6 : bannerServerPath+subChild.img" />
          </div>
          <div fxFlex="50" fxLayoutAlign="start center">
            <span class="tech-name first">{{ subChild.customField20 }}</span>
            <span class="tech-name second">{{ subChild.customField20 }}</span>
          </div>
        </button>
 

      </div>
    </div>
  </div>
</div>