<div [ngStyle.gt-md]="{
    'padding-left': '2rem',
    'padding-right': '2rem',
    'padding-bottom': '5rem',
    'width': '80rem',
    'margin-left': 'auto',
    'margin-right': 'auto'
}" [ngStyle.lt-md]="{
    'padding-left': '1.5rem',
    'padding-right': '1.5rem',
    'margin-left': 'auto',
    'margin-right': 'auto'
}" contentAnim [aniName]="item.customField36">
  <div style="padding-top: 3rem;">
    <calsoft-editor-text [data]="data" [text]="item?.title" [ngStyle.gt-md]="{'font-size': item.customField33 }"
      [ngStyle.lt-md]="{ 'font-size': item.customField34 }"  >
    </calsoft-editor-text>
  </div>

  <div fxLayoutAlign="start center" fxLayout.xs="column" fxLayout="row wrap"  #storyContainer  contentAnim [aniName]="item.customField36">


      <div  *ngFor="let section of images let i=index" fxFlex="45" class="story-card" contentAnim [aniName]="item.customField36"  [ngStyle.gt-md]="{'margin-right': (i % 2 === 0) ? item.gapBetweenImage : '0px','margin-left': (i % 2 != 0) ? item.gapBetweenImage : '0px'}" [ngStyle.lt-md]="{'margin-bottom': '2rem'}"  >
        <div [ngStyle.gt-md]="{'margin-top': section?.customField30}" class="content-wrapper" >
          <div class="image-wrapper1"  contentAnim [aniName]="item.customField36">
            <div class="image-wrapper" (click)="link(section.customField35)">
              <img [style.height]="item.customField23"
                [style.width]="item.customField24" [src]="serverPath1 + section.customField8" [alt]="section?.alt" class="scroll-effect">
              </div>
            </div>
            <h3 (click)="link(section.customField35)"  contentAnim [aniName]="item.customField36" [ngStyle.lt-md]="{'margin-top': '1rem'}"><calsoft-editor-text
              [data]="data"
              [text]="section?.customField6"   [ngStyle.lt-md]="{'font-size': item.customField28}"
              [ngStyle.gt-md]="{'font-size': item.customField27 }">
            </calsoft-editor-text></h3>
            <p (click)="link(section.customField35)"  contentAnim [aniName]="item.customField36"><calsoft-editor-text
              [data]="data"
              [text]="section?.customField7"  [ngStyle.lt-md]="{'font-size': item.customField31}"
              [ngStyle.gt-md]="{'font-size': item.customField29 }">
            </calsoft-editor-text></p>
          </div>
        </div>
      
    </div>
  </div>






