import { Component, Input } from '@angular/core';

@Component({
  selector: 'calsoft-marqeedesign',
  templateUrl: './marqeedesign.component.html',
  styleUrls: ['./marqeedesign.component.scss']
})
export class MarqeedesignComponent {
  
  @Input() item: any;
  @Input() img: any;
  @Input() data: any;
}
